<template>
    <div align="left" justify="center" class="pa-3">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2">
                    mdi-chart-box </v-icon
                >Relatórios > Ofertas > Ofertas Título
            </v-card-text>
        </v-card>
        <v-card class="">
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col lg="12" class="px-1 py-3">
                    <v-card-text class="pa-0"
                        ><v-icon class="mx-2">mdi-information-outline</v-icon
                        >Utilize os campos abaixo para aplicar filtros na
                        pesquisa, após selecionar clique em Exportar
                        Resultados</v-card-text
                    >
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Número do Titulo"
                        placeholder="Número do Título"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.numero_titulo"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-select
                        clearable
                        solo
                        small
                        :items="oferta_renegociacao_items"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-chevron-right"
                        label="Ofertas com Renegociações"
                        placeholder="Ofertas com Renegociações"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.oferta_renegociacao"
                        @keydown.enter="filtrar()"
                    ></v-select>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-select
                        clearable
                        solo
                        small
                        :items="oferta_status_items"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-chevron-right"
                        label="Status da Oferta"
                        placeholder="Status da Oferta"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.oferta_status"
                        @keydown.enter="filtrar()"
                    ></v-select>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-select
                        clearable
                        solo
                        small
                        :items="formas_pagamento_items"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-chevron-right"
                        label="Formas de Pagamento"
                        placeholder="Formas de Pagamento"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.formas_pagamento"
                        @keydown.enter="filtrar()"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-menu
                        solo
                        v-model="menuDataCadastroInicio"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                clearable
                                @click:clear="limparFiltroData()"
                                solo
                                hide-details="auto"
                                :value="filtros.dt_cadastro_de"
                                v-model="filtros.dt_cadastro_de"
                                label="Data de Cadastro Inicial"
                                prepend-icon="mdi-calendar-cursor"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="filtros.dt_cadastro_de"
                            selected-items-text="Datas Selecionadas"
                            color="primary"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-menu
                        solo
                        v-model="menuDataCadastroFim"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                clearable
                                @click:clear="limparFiltroData()"
                                solo
                                hide-details="auto"
                                :value="filtros.dt_cadastro_ate"
                                v-model="filtros.dt_cadastro_ate"
                                label="Data de Cadastro Final"
                                prepend-icon="mdi-calendar-cursor"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="filtros.dt_cadastro_ate"
                            selected-items-text="Datas Selecionadas"
                            color="primary"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Valor(R$) de desconto inicial"
                        placeholder="Valor(R$) de desconto inicial"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.desconto_valorado_de"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Valor(R$) de desconto final"
                        placeholder="Valor(R$) de desconto final"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.desconto_valorado_ate"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Porcentagem(%) de desconto inicial"
                        placeholder="Porcentagem(%) de desconto inicial"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.desconto_porcentagem_de"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Porcentagem(%) de desconto final"
                        placeholder="Porcentagem(%) de desconto final"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.desconto_porcentagem_ate"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3"> </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3"> </v-col>
            </v-row>
            <v-row align="center" justify="end" class="mx-3 mt-3 py-4">
                <v-btn
                    class="mr-3"
                    color="primary"
                    :disabled="false"
                    :loading="loadingFilter"
                    @click="filtrar()"
                >
                    Buscar Resultados
                    <v-icon class="ml-2">mdi-magnify</v-icon>
                </v-btn>
            </v-row>
        </v-card>

        <v-card class="mt-6">
            <v-data-table
                :headers="headers"
                :items="titulos"
                :options.sync="options"
                :server-items-length="totalTitulos"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500, -1]
                }"
                :loading="loadingTable"
                loading-text="Pesquisando títulos..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum resultado encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id`]="{ item }">
                    <span>
                        {{ item.id }}
                    </span>
                </template>
                <template v-slot:[`item.valor_de`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.valor_de) }}
                    </span>
                </template>
                <template v-slot:[`item.valor_ate`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.valor_ate) }}
                    </span>
                </template>
                <template v-slot:[`item.data_inicio`]="{ item }">
                    <span>
                        {{ formatDate(item.data_inicio) }}
                    </span>
                </template>
                <template v-slot:[`item.data_fim`]="{ item }">
                    <span>
                        {{ formatDate(item.data_fim) }}
                    </span>
                </template>
                <template v-slot:[`item.desconto_porcentagem`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.desconto_porcentagem) }}
                    </span>
                </template>
                <template v-slot:[`item.desconto`]="{ item }">
                    <span>
                        {{ formatDinheiroString(item.desconto) }}
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        :color="item.status != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.status"
                        inset
                        :style="{
                            color: item.status != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.cartao`]="{ item }">
                    <v-icon
                        :style="{ color: item.cartao != '' ? 'green' : 'red' }"
                        >{{ getBolean(item.cartao) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.juros_cartao`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_cartao) }}
                    </span>
                </template>
                <template v-slot:[`item.boleto`]="{ item }">
                    <v-icon
                        :style="{ color: item.boleto != '' ? 'green' : 'red' }"
                        >{{ getBolean(item.boleto) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.juros_boleto`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_boleto) }}
                    </span>
                </template>
            </v-data-table>
        </v-card>

        <v-card class="mt-3">
            <v-row class="my-4 ml-4">
                <v-col lg="12" class="text-end">
                    <v-btn
                        class="mx-6"
                        color="primary"
                        :disabled="true"
                        :loading="loadingExport"
                        @click="exportar()"
                    >
                        Exportar resultados
                        <v-icon small class="ma-2">cloud_download</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <Snackbar
            v-if="snackbarDialog"
            :mostrarSnackbar="snackbarDialog"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemRetornoSnackbar"
            @fecharSnackbar="snackbarDialog = false"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
import OfertaService from '@/services/OfertaService';
import * as Formatter from '@/helpers/Formatter';
import { getOnlyNumbers, formatarDecimal } from '@/helpers/Utilitarios';
import lodash from 'lodash';
import Loading from 'vue-loading-overlay';

export default {
    name: 'RelatorioOfertasTitulo',
    components: { Loading },
    data() {
        return {
            formas_pagamento_items: [
                { value: 'ALL_OFFERS', label: 'Todas' },
                { value: 'BANK_SLIP', label: 'Boleto' },
                { value: 'CREDIT_CARD', label: 'Cartão de Crédito' }
            ],
            oferta_status_items: [
                { value: 'ALL_OFFERS', label: 'Todas' },
                { value: 'ACTIVE_OFFERS', label: 'Apenas ativas' },
                { value: 'INACTIVE_OFFERS', label: 'Apenas inativas' }
            ],
            oferta_renegociacao_items: [
                { value: 'ALL_OFFERS', label: 'Todas' },
                { value: 'ACCEPTED_OFFERS', label: 'Ofertas com Negociações' },
                {
                    value: 'NOT_ACCEPTED_OFFERS',
                    label: 'Ofertas sem Negociações'
                }
            ],
            menuDataCadastroFim: null,
            menuDataCadastroInicio: null,
            resultadosOfertasTitulo: [],
            status_oferta_items: [
                { value: '', label: 'Todos' },
                { value: 'true', label: 'Ativo' },
                { value: 'false', label: 'Inativo' }
            ],
            status_renegociacao_items: [
                { value: '', label: 'Todos' },
                { value: 'true', label: 'Ativo' },
                { value: 'false', label: 'Inativo' }
            ],
            status_pagamento_items: [
                { value: '', label: 'Todos' },
                { value: 'DEBT_PAID_OFF', label: 'Pagamento Confirmado' },
                { value: 'WAITING_PAYMENT', label: 'Pendente' }
            ],
            tipo_pagamento_items: [
                { value: '', label: 'Todos' },
                { value: 'boleto', label: 'Boleto' },
                { value: 'cartao', label: 'Cartão de Crédito' }
            ],
            menuDataPermanenciaInicio: null,
            menuDataPermanenciaFim: null,
            menuDataVencimentoInicio: null,
            menuDataVencimentoFim: null,
            headers: [
                {
                    text: 'Id Oferta',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Título',
                    sortable: false,
                    value: 'nosso_numero'
                },
                {
                    text: 'Id do Título',
                    sortable: false,
                    value: 'id_titulo'
                },
                {
                    text: 'Desconto',
                    sortable: false,
                    value: 'desconto'
                },
                {
                    text: 'Desconto Porcentagem',
                    sortable: false,
                    value: 'desconto_porcentagem'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'status'
                },
                {
                    text: 'Boleto',
                    sortable: false,
                    value: 'boleto'
                },
                {
                    text: 'Data de Início',
                    sortable: false,
                    value: 'data_inicio',
                    dataType: 'Date'
                },
                {
                    text: 'Data de Finalização',
                    sortable: false,
                    value: 'data_fim',
                    dataType: 'Date'
                },
                { text: ' ', sortable: false, value: 'action' },
                { text: ' ', sortable: false, value: 'action_desabilitar' }
            ],
            titulos: [],
            totalTitulos: 0,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            statusDisponiveis: [
                { cd_status: 101, nm_status: 'Pago' },
                { cd_status: 107, nm_status: 'Liquidação em condicional' }
            ],
            cd_status_aplicado: [101, 107],
            loadingInicial: false,
            snackbarColor: null,
            snackbarDialog: false,
            snackbarIcon: null,
            mensagemRetornoSnackbar: null,
            loadingFilter: false,
            loadingExport: false,
            loadingTable: false,
            loadingFiltros: false,
            menuDataAtualizacaoRange: false,
            emailReport: '',
            modalReceberRelatorioEmail: false,
            filtros: {
                dt_cadastro_de: null,
                dt_cadastro_ate: null,
                numero_titulo: null,
                desconto_valorado_de: null,
                desconto_valorado_ate: null,
                desconto_porcentagem_de: null,
                desconto_porcentagem_ate: null,
                oferta_renegociacao: null,
                oferta_status: null,
                titulo_status: null,
                formas_pagamento: null,
                ofertas_expiradas: null
            },
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            validEmail: false,
            mostraConfirmacaoEmail: false,
            primaryColorFont: '#757575'
        };
    },
    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.filtrar();
                }
            },
            deep: true
        }
    },
    computed: {
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        listaCarteiras() {
            return this.$store.getters.Carteiras;
        }
    },
    async mounted() {
        this.loadingInicial = true;
        this.loadingInicial = false;

        let tituloService = new TituloService(Vue.http, this.$store);
        tituloService.listaCarteiras();

        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    },
    methods: {
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        formatDate(date) {
            if (!date) return null;
            if (date.includes('-')) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        formatDinheiroString(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return 'R$ ' + Formatter.toString(valor);
            }
        },
        formatPorcentagem(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        getOnlyNumbers,
        formatarDecimal,
        limparFiltroData() {
            this.filtros.dt_atualizacao_range = [];

            this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[0];
            this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[1];
        },
        getNomeCdStatus(listaCdStatus) {
            const nomeStatus = [];
            listaCdStatus.forEach(cdStatus => {
                const statusDisponivel = this.statusDisponiveis.find(
                    status => status.cd_status === cdStatus
                );
                if (statusDisponivel) {
                    nomeStatus.push(statusDisponivel.nm_status);
                }
            });
            return nomeStatus.join(', ');
        },

        getDescSituacao(titulo) {
            return Formatter.getDescSituacaoTitulo(
                titulo.cd_status,
                this.listaSituacoes
            );
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        mostrarSnackbar(icone, cor, mensagem) {
            this.snackbarDialog = true;
            this.snackbarIcon = icone;
            this.snackbarColor = cor;
            this.mensagemRetornoSnackbar = mensagem;
        },
        filtrar() {
            let tituloService = new OfertaService(Vue.http, this.$store);
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            const filtro = lodash.cloneDeep(this.filtros);
            this.loadingFilter = true;
            this.loadingTable = true;

            tituloService
                .consultaRelatorioOfertasTitulo(page, itemsPerPage, filtro)
                .then(
                    response => {
                        if (response.status == 200) {
                            let titulos = response.body.results;
                            const { total } = response.body.count;

                            this.titulos = lodash.cloneDeep(titulos);
                            this.totalTitulos = lodash.cloneDeep(total);

                            this.mostrarSnackbar(
                                'mdi-text-box-check-outline',
                                'success',
                                'Busca efetuada com sucesso!'
                            );
                        }
                    },
                    error => {
                        if (error.status == 400) {
                            this.mostrarSnackbar(
                                'mdi-close',
                                'error',
                                'Erro ao executar o Filtro.'
                            );
                        }
                    }
                )
                .finally(() => {
                    this.loadingFilter = false;
                    this.loadingTable = false;
                });
        },
        dataAtualizacao() {
            this.filtros.dt_atualizacao_range_fmt = [
                Formatter.formatData(this.filtros.dt_atualizacao_range[0]),
                Formatter.formatData(this.filtros.dt_atualizacao_range[1])
            ];
            if (this.filtros.dt_atualizacao_range[1] == null) {
                return this.filtros.dt_atualizacao_range_fmt[0];
            } else if (
                this.filtros.dt_atualizacao_range_fmt[0] >
                this.filtros.dt_atualizacao_range_fmt[1]
            ) {
                this.filtros.dt_atualizacao_range_fmt = this.filtros.dt_atualizacao_range_fmt.reverse();
                return this.filtros.dt_atualizacao_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_atualizacao_range_fmt.join(' - ');
            }
        },
        exportar() {
            this.modalReceberRelatorioEmail = true;
        },
        exportarRelatoriosMsgs() {
            // this.filtros.cd_status = this.cd_status_aplicado;
            this.filtros.cd_apresentante = this.$store.getters.cdApresentante;
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingFiltros = true;
            var emails = [];
            emails[0] = this.emailReport;

            tituloService
                .exportarFinanceiroResumido(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.modalReceberRelatorioEmail = false;
                    this.mostraConfirmacaoEmail = true;
                    this.emailReport = null;
                    this.loadingFiltros = false;
                })
                .catch(() => {
                    this.loadingFiltros = false;
                    this.modalEmailXls = false;
                    this.mostraConfirmacaoEmail = true;
                    this.emailReport = null;
                });
        },
        checkformSubmit() {
            this.checkForm();
        },
        checkForm() {
            if (this.validEmail) {
                return false;
            }
            return true;
        }
    }
};
</script>
